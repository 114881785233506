import { Component, OnInit, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StaticDataStorage } from './global-service/static';
import { CoreCommonService } from './core/core-common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent /*implements OnInit*/ {
  title = 'angular-9-i18n';
  //langs = ['en', 'de'];

  constructor(
    private translateService: TranslateService,
    private staticDataStorage:StaticDataStorage,
    private coreCommonService: CoreCommonService
    ) {}

  public ngOnInit(): void {
    //Language detection
    let browserLang = this.translateService.getBrowserLang();
    //let langPath = this.staticDataStorage.getLanguagePath;
    //console.log("Language: ",browserLang)
   
   /* if (this.staticDataStorage.getLanguages.indexOf(browserLang) > -1) {
    
        this.translateService.setDefaultLang(browserLang);
     
    } else {
      this.translateService.setDefaultLang('en');
    }*/
    this.coreCommonService.setLanguage(null); //automatic
  
    this.translateService.get('errorcodes').subscribe((codes: string) => {
      //console.log(codes);
      this.staticDataStorage.setErrorCodes(codes);
     
    });
    
  }


  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }
}
