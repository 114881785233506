import { formatMMDDYYYY } from './../../global-service/utils';
import { GlobalApiService } from './../../global-service/global-api-services';
import { GlobalService } from './../../global-service/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { utils, write, WorkBook } from 'xlsx';

import { saveAs } from 'file-saver';


@Component({
  selector: 'report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.css']
})
export class ReportModalComponent implements OnInit, OnDestroy {

  reportPopupSubscription = new Subscription();
  ApiServiceSubscription = new Subscription();
  popupElement: {} = false;

  TransactionComment: string = "";
  date: any;

  constructor(
    private global: GlobalService,
    private globalAPIService: GlobalApiService
  ) { }

  ngOnInit() {
    //
    this.popupElement = null;
    //
    this.reportPopupSubscription = this.global.reportPopupSubject.subscribe(
      () => {
        this.popupElement = true;
      }
    );
    //

    //
  }

  onAdministrationReport() {
    this.ApiServiceSubscription = this.globalAPIService.AdministrationReport().subscribe(
      (httpReasult: any) => {
        if (httpReasult) {
          this.generateExcel(httpReasult.ResponseBody, 'Administration_Report');
        }
      })
  }

  onBusinessReport() {
    this.ApiServiceSubscription = this.globalAPIService.BusinessReport().subscribe(
      (httpReasult: any) => {
        if (httpReasult) {
          this.generateExcel(httpReasult.ResponseBody, 'Business_Report');
        }
      })
  }

  generateExcel(data: Array<{}>, sheetName: string) {
    data = this.filterReportData(data, sheetName);
    const ws_name = sheetName;
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = utils.json_to_sheet(data);
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const wbout = write(wb, {
      bookType: 'xlsx', bookSST: true, type:
        'binary'
    })
    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), sheetName+'.xlsx');
  }



  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    };
    return buf;
  }


  close() {
    this.popupElement = null;
  }

  filterReportData(data: Array<{}>, sheetName:string): Array<{}> {
    //var filteredArray: Array<{}> = [];
    for (var i = 0; i < data.length; i++) {
      if(sheetName=='Administration_Report'){
        data[i]["Registration Date"] = formatMMDDYYYY(data[i]["Registration Date"]);
      }else if(sheetName=='Business_Report'){
        data[i]["Date Of Payment"] = formatMMDDYYYY(data[i]["Date Of Payment"]);
      }
      
    }
    return data
  }

  ngOnDestroy() {
    this.reportPopupSubscription.unsubscribe();
    this.ApiServiceSubscription.unsubscribe();
    this.popupElement = false;
  }

}

