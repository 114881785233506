import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { StaticDataStorage } from './../global-service/static';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';


@Injectable()
export class GlobalApiService {
    //requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    constructor(
        private http: HttpClient,
        private global: GlobalService,
        private staticDataStorage: StaticDataStorage
    ) { }

    GetLearnerCountByCustomerId(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetLearnerCountByCustomerId'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserCountByCustomerId(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserCountByCustomerId'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetLearnerListByCustomerId(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetLearnerListByCustomerId'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetAdminDashboardUserList(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetAdminDashboardUserList'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserDetailsById(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserDetailsById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetConsultantListByCustomerId(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetConsultantListByCustomerId'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetFrontDeskListByCustomerId(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetFrontDeskListByCustomerId'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    CreateUser(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/CreateUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdateUser(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/UpdateUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SortUserByParameter(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/SortUserByParameter'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetClinicalDiag(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Diagnosis/GetClinicalDiag'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    CreateClinicalDiag(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Diagnosis/CreateClinicalDiag'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetQuestionnaireAns() {
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Diagnosis/GetQuestionnaireAns'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    
    CreateLearnerQuestionnaire(RequestBody: Array<{}>) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Diagnosis/CreateLearnerQuestionnaire'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


   

    UpdateLicenseStatus(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Diagnosis/UpdateLicenseStatus'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    
    SearchUserByName(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/SearchUserByName'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdateTherapyLicenseStatus(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Diagnosis/UpdateTherapyLicenseStatus'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
  
//
CreateCustomer(RequestBody: {}) {
    let requestObj: object = { 'RequestBody': RequestBody };
    let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/CreateCustomer'), requestObj, requestHeader)
        .map(
            (httpReasult: any) => {
                return httpReasult;
            }
        )
}
//
    CustomerCount() {
        //  let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/CustomerCount'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    DisplayCustomer(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/DisplayCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetCustomer(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    UpdateCustomer(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/UpdateCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    ValidateCustomer(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/ValidateCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdatePayment (RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/UpdatePayment'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SaveLicenses (RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/SaveLicenses'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetSADashboard () {
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetSADashboard'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    AdministrationReport () {
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'customer/AdministrationReport'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    BusinessReport () {
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'customer/BusinessReport'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    UploadGSTFiles(fileToUpload: FormData) {
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };        
        return this.http.post((this.staticDataStorage.getStaticURL + 'customer/UploadGSTFiles'), fileToUpload, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetRegPartnersList(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetCustomers'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetPartnersCount(){
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/CustomerCount'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetReportsCount(){
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/CustomerCount'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetAdministrationReport(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/AdministrationReport'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetBusinessReport(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/BusinessReport'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetLearnerReport(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/LearnerReport'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    
    GetMediaDataByID(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetMediaItemById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetFeedbackReport(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/FeedbackReport'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }








    GetRegIndividualsList(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/SortUserByParameter'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetPlaylists(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetPlaylistForCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdatePlaylistData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + '/Application/AddUpdatePlaylist'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
    

    GetApplications(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetApplications'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetApplicationData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetApplicationById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetApplicationById(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetApplicationById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    AddPartner(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/CreateCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    UpdatePartner(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/UpdateCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetLicenseProvide(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/SaveLicenses'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetTransactionsData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserTransactionsData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserDetailsById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SaveUserApplicationData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/SaveUserLicenses'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    DeleteApplicationItem(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/DeleteApplicationById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    AddApplication(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/CreateApplication'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    CreateApplicationWithGallery(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/CreateApplicationWithGallery'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    EditApplication(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/EditApplication'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    EditApplicationWithGallery(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/EditApplicationWithGallery'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    getIndividualData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserDetailsById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    AddIndividual(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/CreateUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    UpdateIndividual(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/UpdateUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    ApproveParticipantTempUser(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/ApproveParticipantTempUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SaveIndividualLicenses(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/SaveLicenses'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UploadImageFiles(fileToUpload: FormData){
       // let requestObj: object = { 'RequestBody': RequestBody };
        //let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        let requestHeader: object = { headers: new HttpHeaders().set("imageUpload", "true") };     
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/UploadFiles'), fileToUpload, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    /******* Customer  ***********/

    GetCUDashboard(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetPartnerDashboard'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetCustomerCount(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetCountByCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetParticipantAssignmentData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetParticipantsForAssignment'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetParticipantPlaylistAssignmentData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetParticipantsForPlaylistAssignment'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    AssignParticipants(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/AssignParticipants'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
    AssignParticipantsToPlaylist(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/AssignParticipantsToPlaylist'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetParticipantsList(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetLearnerListWithAppLicense'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetTempUserDetailsForApproval(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetTempUserDetailsForApproval'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
    

    UploadUserFiles(fileToUpload: FormData){
        // let requestObj: object = { 'RequestBody': RequestBody };
         //let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
         let requestHeader: object = { headers: new HttpHeaders().set("imageUpload", "true") };     
         return this.http.post((this.staticDataStorage.getStaticURL + 'User/UploadFiles'), fileToUpload, requestHeader)
             .map(
                 (httpReasult: any) => {
                     return httpReasult;
                 }
             )
     }

    //UploadMultipleUsers(RequestBody: {}){
    UploadMultipleUsers(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        //let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/UploadMultipleUsers'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetParticipantData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserDetailsById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetAssignmentData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetApplicationsForUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetApplicationsForUser(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetApplicationsForUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SaveAssignmentForUser(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/AddNewAssignment'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdateAssignmentForUser(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/UpdateAssignmentExpDate'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetDeviceList(){
        //let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Common/GetDeviceList'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetCustomerDeviceList(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetCustomerDeviceList'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetApplicationTypes(){
        //let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetApplicationTypes'),  requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetMediaGalleryList(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetMediaItems'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetLanguages(){
        //let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Common/GetLanguages'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SaveMediaData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/AddUpdateMedia'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetCustomerSettings(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/GetCustomerSettings'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdateCustomerSettings(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/AddUpdateCustomerSettings'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetApplicationLeaderboard(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetCustomerApplicationLeaderboard'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetCustomerPlaylistAssignmentData(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Application/GetCustomersForPlaylistAssignment'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
    AssignPlaylistToCustomer(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Customer/AssignPlaylistToCustomer'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
}
