import { FormGroup, FormControl, Validators } from '@angular/forms';
import { formatMMDDYYYY } from './../../global-service/utils';
import { GlobalService } from './../../global-service/global.service';
import { Router, NavigationStart, NavigationError, NavigationCancel, NavigationEnd } from '@angular/router';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as $ from 'jquery';


@Component({
  selector: 'transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.css']
})
export class TransactionModalComponent implements OnInit, OnDestroy {

  transactionPopupSubscription = new Subscription();
  popupElement: {} = false;

  TransactionComment:string = "";
  date: any;

  TransactionForm: FormGroup;

  commentControl = new FormControl('', [Validators.required]);
  dateControl = new FormControl('', [Validators.required]);

  constructor(private global: GlobalService) { }

  ngOnInit() {
    //
    this.popupElement = null;
    //
    this.transactionPopupSubscription = this.global.transactionPopupSubject.subscribe(
      () => {
        this.popupElement = true;
      }
    );
    //
    this.initForm();
    //
  }

  initForm() {
    this.TransactionForm = new FormGroup({
      'commentControl': this.commentControl,
      'dateControl': this.dateControl
    });
  }

  onLicenseSubmit(){
    var dateString: string;
    if (this.TransactionForm.value["dateControl"] != null) {
      dateString = formatMMDDYYYY(this.TransactionForm.value["dateControl"]);
    } else {
      dateString = null;
    }
    this.global.onTransactionSubmit(this.TransactionForm.value["commentControl"], dateString);
    this.close();
  }

  getToday(): string {
    return new Date().toISOString().split('T')[0]
 }
  
  onLicenseCancel(){
    this.close();
  }


  close() {
    this.TransactionComment = "";
    this.popupElement = null;
    this.TransactionForm.reset();
  }

  getCommentErrorMessage() {
    return this.commentControl.hasError('required') ? 'You must enter a comment.' : "";
  }

  getDateErrorMessage() {
    return this.dateControl.hasError('required') ? 'You must select a date.' : "";
  }

  ngOnDestroy() {
    this.transactionPopupSubscription.unsubscribe();
    this.popupElement = false;
  }

}
