import { Router } from '@angular/router';
import { StaticDataStorage } from './static';
import { CoreCommonService } from './../core/core-common.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';


import { GlobalService } from './global.service';
import { CryptoService } from './Crypto.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private cryptoService: CryptoService,
        private coreCommonService: CoreCommonService,
        private global: GlobalService,
        private staticDataStorage: StaticDataStorage,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.handelGlobalPopup(true)

        req.headers.set('Content-Type', 'application/json');

        if (req.body.noAuth) {
            return next.handle(req).do(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.handelGlobalPopup(false);
                        //
                        if (event.body.StatusCode == 200 && event.status == 200 && event.body.ResponseBody) {
                            const responseObject = this.cryptoService.decryptCryptoToJSON(event.body.ResponseBody, this.coreCommonService.getcryptoKey());
                            event.body.ResponseBody = responseObject;
                            return event;
                        } else if (event.status == 200) {
                            return event;
                        } else if (event.body.StatusCode != 200 || event.status != 200) {
                            // return this.handelHttpErrorResponse("", "Authentication can't be verified, please refresh the browser window and try again.")
                        }
                    }
                }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        return this.handelHttpErrorResponse("", parseFloat(err.statusText));
                    }
                }
            );
        } else {
            if (this.coreCommonService.getcryptoKey()) {
                req.body.RequestBody = this.cryptoService.encryptStringCrypto(JSON.stringify(req.body.RequestBody), this.coreCommonService.getcryptoKey());
            }
            if (this.coreCommonService.getJWTToken()) {
                const copiedReq = req.clone({
                    setHeaders: {
                        /*Authorization: `Bearer ` + this.coreCommonService.getJWTToken())*/
                        Authorization: this.coreCommonService.getJWTToken()
                    }
                });
                return next.handle(copiedReq).do(
                    (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.handelGlobalPopup(false);
                            if (event.body.Certificate) {
                                if (event.body.Certificate.length > 5) {
                                    this.coreCommonService.setJWTToken(event.body.Certificate);
                                }
                            }
                            if (event.body.StatusCode == 200 && event.status == 200 && event.body.ResponseBody) {
                                const responseObject = this.cryptoService.decryptCryptoToJSON(event.body.ResponseBody, this.coreCommonService.getcryptoKey());
                                event.body.ResponseBody = responseObject;
                                return event;
                            } else if (event.status == 200) {
                                return event;
                            } else if (event.body.StatusCode != 200 || event.status != 200) {
                                // return this.handelHttpErrorResponse("", "Authentication can't be verified, please refresh the browser window and try again.")
                            }
                        }
                    }, (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                            return this.handelHttpErrorResponse("", parseFloat(err.statusText));
                        }
                    }
                )
            } else {
                return next.handle(req).do(
                    (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.handelGlobalPopup(false);
                            if (event.body.Certificate) {
                                if (event.body.Certificate.length > 5) {
                                    this.coreCommonService.setJWTToken(event.body.Certificate);
                                }
                            }
                            if (event.body.StatusCode == 200 && event.status == 200 && event.body.ResponseBody) {
                                const responseObject = this.cryptoService.decryptCryptoToJSON(event.body.ResponseBody, this.coreCommonService.getcryptoKey());
                                event.body.ResponseBody = responseObject;
                                return event;
                            } else if (event.body.StatusCode != 200 || event.status != 200) {
                                // return this.handelHttpErrorResponse("", "Authentication can't be verified, please refresh the browser window and try again.")
                            }
                        }
                    }, (err: any) => {
                        return this.handelHttpErrorResponse(err.statusText, parseFloat(err.statusText));
                    }
                )
            }

        }
    }

    handelHttpErrorResponse(errHeader: string, errCode: number) {
        //
       
        if ((errCode == 401 || errCode == 501 || isNaN(errCode)) && this.router.url != "/") {
            this.coreCommonService.deleteUser();
            
        }
        if ((errCode == 401 || errCode == 501 || isNaN(errCode))) {
            this.coreCommonService.setForceExit(true);
        }
        //
        var _this = this;
        setTimeout(function () {
            _this.global.onGeneratePopup({ "title": ("Warning "), "body": (String(_this.staticDataStorage.ErrorCode[String(errCode)])) });
        }, 500);

        this.handelGlobalPopup(false);
        return Observable.of(false);
    }

    handelGlobalPopup(flag: boolean) {
        if (flag) {
            this.global.onLoaderShow();
        } else {
            this.global.onLoaderHide();
        }

    }
}
