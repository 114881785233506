import { Subject } from 'rxjs/Subject';
import { Injectable, OnInit, OnDestroy } from '@angular/core';

@Injectable()
export class GlobalService implements OnInit, OnDestroy {

  globalPopupSubject = new Subject();
  showGlobalLoaderSubject = new Subject();
  hideGlobalLoaderSubject = new Subject();
  userDetailsSubject = new Subject();
  questionnaireHistorySubject = new Subject();
  diagnosisReportHistorySubject = new Subject();
  therapeuticReportHistorySubject = new Subject();

  transactionPopupSubject = new Subject();
  onTransactionSubmitSubject = new Subject();

  reportPopupSubject = new Subject();
  //
  remainingLicensesSubject = new Subject();
  //
  continueRoutingSubject = new Subject();
  //
  previuosRoute:string;
  //
  constructor() { }

  //On Error Popup
  onGeneratePopup(popupElement: {}) {
    this.globalPopupSubject.next(popupElement);
  };

  //On Loading start
  onLoaderShow() {
    this.showGlobalLoaderSubject.next();
  };

  onLoaderHide() {
    this.hideGlobalLoaderSubject.next();
  };

  onUserDetails(userData: {}) {
    this.userDetailsSubject.next(userData);
  };
  //
  onLicenseCountUpdate(licenseData) {    
    this.remainingLicensesSubject.next(licenseData);
  };
  //
  onContinueRoute(routeUrl:string) {    
    this.continueRoutingSubject.next(routeUrl);
  };
  //
  setPreviousRoute(path:string){
    this.previuosRoute = path;
  }
  getPreviousRoute(){
    return this.previuosRoute;
  }
  onQuestionnaireHistory(formNo:number){
    this.questionnaireHistorySubject.next(formNo);
  }  
  onDiagnosisReportHistory(licenseId:number){
    this.diagnosisReportHistorySubject.next(licenseId);
  }
  onTherapeuticReportHistory(licenseId:number){
    this.therapeuticReportHistorySubject.next(licenseId);
  }
  onReportPopupSubject(){
    this.reportPopupSubject.next();
  }
  onTransactionModal(){
    this.transactionPopupSubject.next();
  }
  onTransactionSubmit(value:string, date:string){
    this.onTransactionSubmitSubject.next({"value":value , "date":date});
  }
  //
  ngOnInit() {
  }

  ngOnDestroy() {
    this.globalPopupSubject.unsubscribe();
    this.showGlobalLoaderSubject.unsubscribe();
    this.hideGlobalLoaderSubject.unsubscribe();
    this.userDetailsSubject.unsubscribe();
    this.remainingLicensesSubject.unsubscribe();
    this.continueRoutingSubject.unsubscribe();
    this.questionnaireHistorySubject.unsubscribe();
    this.diagnosisReportHistorySubject.unsubscribe();
    this.therapeuticReportHistorySubject.unsubscribe();

    this.transactionPopupSubject.unsubscribe();
    this.onTransactionSubmitSubject.unsubscribe();
    this.reportPopupSubject.unsubscribe();
  }

}
