import { Router } from '@angular/router';
import { StaticDataStorage } from './../global-service/static';
import { CryptoService } from './../global-service/Crypto.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CoreCommonService {

  private authenticate: Boolean;

  private cryptoKey: string;

  private JWTToken: string;

  private UserId: number;

  private UserDetailsId: number;

  private UserRole: string;

  private GUID: string;

  private CustomerName: string;

  private UserCustomerId: number;

  private UserDetails: {};

  private currentRoute: string;

  private customerId: number;

  private customerDefaultLanguage: number;

  private roleArray: Array<{}>;

  private forceExit: boolean = false;

  private selectedCustomer: any;

  private selectedApp: any;

  private selectedPlaylist: any;

  private selectedMedia: any;

  private currency: any = "SGD";
  private selectedIndividual: any;

  private dialogRef: any;

  private language: string;

  private availableApplicationTypes : Array<{}>;
  private availableLanguages : Array<{}>;

  constructor(
    private cryptoService: CryptoService, 
    private staticDataStorage: StaticDataStorage, 
    private router: Router,
    private translateService: TranslateService
    ) { }

  /////////////////

  setLanguage(lang:string){
    if(!lang){
      //If language is not mentioned.
      //Set default language
      let browserLang = this.translateService.getBrowserLang();

      let isCompatibleLang = false;
      this.staticDataStorage.getLanguages.filter(function(item){
        if(item['code'] == browserLang){
          isCompatibleLang = true;
          return;
        } 
      })

      //console.log("isCompatibleLang: ",isCompatibleLang)

      if (isCompatibleLang) {
        this.translateService.setDefaultLang(browserLang);
      } else {
        this.translateService.setDefaultLang('EN');
      }
    }else{
      //Set mentioned language
      this.translateService.setDefaultLang(lang);
    }

    //Set language specific Error codes
    this.translateService.get('errorcodes').subscribe((codes: string) => {
      this.staticDataStorage.setErrorCodes(codes);
    });
    //Set language specific loader message
    this.translateService.get('loaderMsg').subscribe((msg: string) => {
      this.staticDataStorage.setLoaderMsg(msg);
    });
  }

  get getDefaultLanguage():string{
    return this.translateService.getDefaultLang();
  }

  get getLanguages():Array<any>{
    return this.staticDataStorage.getLanguages;
  }

  getUserAuthenticate() {
    return this.authenticate;
  }

  setUserAuthenticate(bool: Boolean) {
    this.authenticate = bool;
  }

  deleteKey() {
    this.authenticate = false;
  }

  ////////////////////

  setcryptoKey(key: string) {
    this.cryptoKey = key;
  }

  getcryptoKey(): string {
    return this.cryptoKey;
  }

  /////////////////////

  setJWTToken(key: string) {
    this.JWTToken = key;
  }

  getJWTToken(): string {
    return this.JWTToken;
  }

  /////////////////////


  /////////////////////

  setUserId(userId: number) {
    this.UserId = userId;
  }

  getUserId(): number {
    return this.UserId;
  }

  /////////////////////

  /////////////////////
  setUserDetailsId(UserDetailsId: number) {
    this.UserDetailsId = UserDetailsId;
    //console.log("SET USER DETAILS ID: ", UserDetailsId)
  }

  getUserDetailsId(): number {
    return this.UserDetailsId;
  }

  /////////////////////

  /////////////////////

  setUserRole(userRole: string) {
    this.UserRole = this.cryptoService.encryptStringCrypto(userRole, this.staticDataStorage.getLocalCrypto);
  }

  setUserRoleWithEncryption(userRole: string) {
    this.UserRole = userRole;
  }

  getDecryptedUserRole(): string {
    return this.cryptoService.decryptCryptoToString(this.UserRole, this.staticDataStorage.getLocalCrypto);
  }

  getUserRole(): string {
    return this.UserRole;
  }

  /////////////////////////
  setGUID(GUID: string) {
    this.GUID = GUID;
  }

  getGUID(): string {
    return this.GUID;
  }

  /////////////////////


  /////////////////////

  setCustomerName(customerName: string) {
    this.CustomerName = this.cryptoService.encryptStringCrypto(customerName, this.staticDataStorage.getLocalCrypto);
  }

  getCustomerName(): string {
    return this.cryptoService.decryptCryptoToString(this.CustomerName, this.staticDataStorage.getLocalCrypto);
  }

  /////////////////////////


  ////////////////////

  setUserCustomerId(UserCustomerId: number) {
    this.UserCustomerId = UserCustomerId;
  }

  getUserCustomerId(): number {
    return this.UserCustomerId;
  }

  /////////////////////////

  /////////////////////////


  setUserDetails(UserDetails: {}) {
    this.UserDetails = UserDetails;
  }

  getUserDetails(): {} {
    return this.UserDetails;
  }

  /////////////////////////

  /////////////////////////



  setCurrentRoute(currentRoute: string) {
    this.currentRoute = currentRoute;
  }

  getCurrentRoute(): {} {
    return this.currentRoute;
  }

  /////////////////////////

  /////////////////////////


  setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  getCustomerId() {
    return this.customerId;
  }

  /////////////////////////

  /////////////////////////




  setRoleArray(roleArray: Array<{}>) {
    this.roleArray = roleArray;
  }

  getRoleId(name: string): number {
    var roleId;
    for (var i = 0; i < this.roleArray.length; i++) {
      if (name == this.roleArray[i]["RoleName"]) {
        roleId = this.roleArray[i]["RoleID"];
        break;
      }
    }
    return roleId;
  }

  /////////////////////////

  /////////////////////////

  setForceExit(forceExit: boolean) {
    this.forceExit = forceExit;
  }

  getForceExit(): boolean {    
    return this.forceExit;
  }

  /////////////////////////

  /////////////////////////

  deleteUser() {
    this.authenticate = false;

    this.JWTToken = null;

    this.UserId = null;

    this.UserRole = null;

    this.CustomerName = null;

    this.UserCustomerId = null;

    this.UserDetails = {};

    this.currentRoute = null;

    this.customerId = null;

    this.roleArray = null;

    this.router.navigate(["/"])

  }

  deleteSAUser() {
    this.authenticate = false;

    this.JWTToken = null;

    this.UserId = null;

    this.UserRole = null;

    this.CustomerName = null;

    this.UserCustomerId = null;

    this.UserDetails = {};

    this.currentRoute = null;

    this.customerId = null;

    this.roleArray = null;

    this.router.navigate(["SALogin"])

  }

  getSelectedCustomer(){
    //console.log(this.selectedCustomer);
    return this.selectedCustomer
  }

  setSelectedCustomer(val:any){
    //console.log(val);
    this.selectedCustomer = val;
  }

  getSelectedApp(){
    return this.selectedApp;
  }

  setSelectedApp(val:any){
    this.selectedApp = val;
  }

  getSelectedPlaylist(){
    return this.selectedPlaylist;
  }

  setSelectedPlaylist(val:any){
    this.selectedPlaylist = val;
  }

  getSelectedMedia(){
    return this.selectedMedia;
  }

  setSelectedMedia(val:any){
    this.selectedMedia = val;
  }
  
  getCurrency(){
    return this.currency
  }

  setCurrency(val:any){
    this.currency = val;
  }

  getSelectedIndividual(){
    return this.selectedIndividual
  }

  setSelectedIndividual(val:any){
    this.selectedIndividual = val;
  }

  getDialogRef(){
    return this.dialogRef;
  }

  setDialogRef(val:any){
    this.dialogRef = val;
  }

  //Language
  getLanguage(){
    return this.language;
  }

  /*setLanguage(val:any){
    this.language = val;
  }*/

  getCustomerDefaultLanguage(){
    return this.customerDefaultLanguage;
  }

  setCustomerDefaultLanguage(val:number){
    this.customerDefaultLanguage = val;
  }

  getAvailableLanguages(){
    return this.availableLanguages;
  }

  setAvailableLanguages(val: any[]){
    this.availableLanguages = val;
  }

  getAvailableApplicationTypes(){
    return this.availableApplicationTypes;
  }

  setAvailableApplicationTypes(val: any[]){
    this.availableApplicationTypes = val;
  }
}
