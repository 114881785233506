import { StaticDataStorage } from './static';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { environment } from './../../environments/environment';


@Injectable()
export class AppConfig {

    constructor(private http: Http, private staticDataStorage:StaticDataStorage) {}

    load() {
        const jsonFile = `assets/config/config.${environment.name}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response : Response) => {
                this.staticDataStorage.setStaticURL(response.json().apiServer.url);
                //this.staticDataStorage.setGameURL(response.json().apiServer.gameurl);
                this.staticDataStorage.setLanguages(response.json().localization.languages);
                //this.staticDataStorage.setLanguagePath(response.json().localization.basepath);
                
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}