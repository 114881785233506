
import { AppConfig } from './global-service/app.config';
import { MaterialModule } from './material/material.module';

import { PartnerSelectionComponent } from './shared/partner-selection/partner-selection.component';
import { APILoaderComponent } from './modal/API-loader/API-loader.component';
import { RouterLoaderComponent } from './modal/router-loader/router-loader.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';

import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule} from '@angular/material/core';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule} from '@angular/material/sort';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRouterModule } from './app-router/app-router.module';
import { CoreApiService } from './core/core-api-services';
import { AuthGuardService } from './global-service/auth-guard.service';
import { StaticDataStorage } from './global-service/static';
import { CryptoService } from './global-service/Crypto.service';
import { CryptoKeyResolver } from './global-service/ctypto-resolver';
import { GlobalModalComponent } from './modal/global-modal/global-modal.component';
import { AuthInterceptor } from './global-service/auth-interceptor';
import { GlobalService } from './global-service/global.service';
import { CookieService } from 'ngx-cookie-service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SuccessSnackBarComponent } from './modal/success-snack-bar/success-snack-bar.component';
import { HttpModule } from '@angular/http';
import { DeactivateGuardService } from './global-service/deactivateGuard.service';

import { TransactionModalComponent } from './modal/transaction-modal/transaction-modal.component';
import { ReportModalComponent } from './modal/report-modal/report-modal.component';

import { ChartsModule } from 'ng2-charts';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';


import { from } from 'rxjs/observable/from';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function TranslationLoaderFactory(handler: HttpBackend) {
  const http = new HttpClient(handler);
  //return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http,'assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    AppComponent,
    GlobalModalComponent,
    RouterLoaderComponent,
    APILoaderComponent,
    PartnerSelectionComponent,
    SuccessSnackBarComponent,
    TransactionModalComponent,
    ReportModalComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRouterModule,
    HttpClientModule,
    FormsModule,
    MatSliderModule,MatTabsModule,MatTableModule,MatProgressBarModule,MatGridListModule,
    MatProgressSpinnerModule,MatInputModule,MatDialogModule,MatPaginatorModule, MatCheckboxModule,
    MatListModule,MatSelectModule,MatDatepickerModule,MatNativeDateModule,MatSortModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpBackend]}
    }),
    NgIdleKeepaliveModule.forRoot(),
    
    NgbModule
    /*UserIdleModule.forRoot({idle: 60, timeout: 30, ping: 10})*/
  ],
  providers: [
    CoreApiService,
    CookieService, 
    AuthGuardService,
    StaticDataStorage,
    CryptoKeyResolver,
    CryptoService,
    GlobalService,    
    AppConfig,
    DeactivateGuardService,
    //{provide: PLATFORM_PIPES, useValue: TranslatePipe, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true}
  ], 
  entryComponents: [
    
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AppModule { }

