import { PreventNegativeDirective } from './ignoreNegative.directive';
import { ClickOutside } from './click-outside';
import { NgModule } from '@angular/core';
import { InputPatternDirective } from './input-pattern.directive';
import { AllowNegativeDirective } from './AllowNegative.directive';


@NgModule({
  imports:[
    
  ],
  declarations: [
    ClickOutside,
    InputPatternDirective,
    PreventNegativeDirective,
    AllowNegativeDirective,
    
  ],
  exports: [
    ClickOutside,
    InputPatternDirective,
    PreventNegativeDirective,
    AllowNegativeDirective
  ],
  entryComponents: [
    
  ]
})
export class SharedModuleModule { }
