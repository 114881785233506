export class LoginModel {
    public userName: String;
    public password: String;
    constructor(
        sUserName: String,
        sPassword: String,
    ) {
        this.userName = sUserName;
        this.password = sPassword;
    }
}


export class HospitalSelectionModel {
    public HospitalName: String;
    public HospitalId: Number;
    constructor(
        sHospitalName: String,
        sHospitalId: Number,
    ) {
        this.HospitalName = sHospitalName;
        this.HospitalId = sHospitalId;
    }
}

export class DoctorListElement {
    public doctorName: String;
    public doctorId: Number;
    constructor(
        sDoctorName: String,
        nDoctorId: Number,
    ) {
        this.doctorName = sDoctorName;
        this.doctorId = nDoctorId;
    }
}



export class FrontDeskListElement {
    public userName: String;
    public userId: Number;
    constructor(
        sUserName: String,
        nUserId: Number,
    ) {
        this.userName = sUserName;
        this.userId = nUserId;
    }
}




export class LearnerListElement {
    public name: String;
    public age: Number;
    public gender: String;
    public lastCheckup: Date;
    public userId: Number;
    constructor(
        sName: String,
        nAge: Number,
        sGender: String,
        dLastCheckup: Date,
        nUserId: Number
    ) {
        this.name = sName;
        this.age = nAge;
        this.gender = sGender;
        this.lastCheckup = dLastCheckup;
        this.userId = nUserId;
    }
}





export class UserManagementElement {

    public Firstname: string;
    public Middlename: string;
    public Lastname: string;
    public Gender: string;
    public Speciality: string;
    public Profession: string;
    public DateOfBirth: any;
    public RegnNumber: number;
    public RegnBody: string;
    public City: string;
    public State: string;
    public Country: string;
    public Address: string;
    public UserLanguage: string;
    public ContactNumber: number;
    public Email: string;
    public CustomerId: number;
    public OrganizationName: string;
    public RoleId: number;
    public UserId: number;

    constructor(
        sFirstname: string,
        sMiddlename: string,
        sLastname: string,
        sGender: string,
        sSpeciality: string,
        sProfession: string,
        dDateOfBirth: any,
        nRegnNumber: number,
        sRegnBody: string,
        sCity: string,
        sState: string,
        sCountry: string,
        sAddress: string,
        nUserLanguage: string,
        nContactNumber: number,
        sEmail: string,
        nCustomerId: number,
        sOrganizationName: string,
        nRoleId: number,
        nUserId: number,
    ) {
        this.Firstname = sFirstname;
        this.Middlename = sMiddlename;
        this.Lastname = sLastname;
        this.Gender = sGender;
        this.Speciality = sSpeciality;
        this.Profession = sProfession;
        this.DateOfBirth = dDateOfBirth;
        this.RegnNumber = nRegnNumber;
        this.RegnBody = sRegnBody;
        this.City = sCity;
        this.State = sState;
        this.Country = sCountry;
        this.Address = sAddress;
        this.UserLanguage = nUserLanguage;
        this.ContactNumber = nContactNumber;
        this.Email = sEmail;
        this.CustomerId = nCustomerId;
        this.OrganizationName = sOrganizationName;
        this.RoleId = nRoleId;
        this.UserId = nUserId;
    }
}


//
export class clinicalFindingsData {
    public IPD: number;
    public R_Sph: number;
    public R_Cyl: number;
    public R_Axis: number;
    public R_Add: number;
    public R_VA_Distance: number;
    public R_DistanceLogMARValue: string;
    public R_VA_Near: number;
    public R_NearLogMARValue: number;
    public R_ClinicalDiag: string;
    public L_Sph: number;
    public L_Cyl: number;
    public L_Axis: number;
    public L_Add: number;
    public L_VA_Distance: number;
    public L_DistanceLogMARValue: string;
    public L_VA_Near: number;
    public L_NearLogMARValue: number;
    public L_ClinicalDiag: string;
    public TestedVerified: string;
    public GlassesStatus: string;
    public VAInputType: string;

    constructor(
        IPD: number,
        R_Sph: number,
        R_Cyl: number,
        R_Axis: number,
        R_Add: number,
        R_VA_Distance: number,
        R_DistanceLogMARValue: string,
        R_VA_Near: number,
        R_NearLogMARValue: number,
        R_ClinicalDiag: string,
        L_Sph: number,
        L_Cyl: number,
        L_Axis: number,
        L_Add: number,
        L_VA_Distance: number,
        L_DistanceLogMARValue: string,
        L_VA_Near: number,
        L_NearLogMARValue: number,
        L_ClinicalDiag: string,
        TestedVerified: string,
        GlassesStatus: string,
        VAInputType: string
    ) {
        this.IPD = IPD;
        this.R_Sph = R_Sph;
        this.R_Cyl = R_Cyl;
        this.R_Axis = R_Axis;
        this.R_Add = R_Add;
        this.R_VA_Distance = R_VA_Distance;
        this.R_DistanceLogMARValue = R_DistanceLogMARValue;
        this.R_VA_Near = R_VA_Near;
        this.R_NearLogMARValue = R_NearLogMARValue;
        this.R_ClinicalDiag = R_ClinicalDiag;
        this.L_Sph = L_Sph;
        this.L_Cyl = L_Cyl;
        this.L_Axis = L_Axis;
        this.L_Add = L_Add;
        this.L_VA_Distance = L_VA_Distance;
        this.L_DistanceLogMARValue = L_DistanceLogMARValue;
        this.L_VA_Near = L_VA_Near;
        this.L_NearLogMARValue = L_NearLogMARValue;
        this.L_ClinicalDiag = L_ClinicalDiag;
        this.TestedVerified = TestedVerified;
        this.GlassesStatus = GlassesStatus;
        this.VAInputType = VAInputType;
    }
}


export class UserProfile {
    public Firstname: string;
    public Middlename: string;
    public Lastname: string;
    public Gender: string;
    public Speciality: string;
    public Profession: string;
    public DateOfBirth: any;
    public RegnNumber: number;
    public RegnBody: string;
    public City: string;
    public State: string;
    public Country: string;
    public Address: string;
    public ContactNumber: number;
    public Email: string;

    constructor(
        sFirstname: string,
        sMiddlename: string,
        sLastname: string,
        sGender: string,
        sSpeciality: string,
        sProfession: string,
        dDateOfBirth: any,
        nRegnNumber: number,
        sRegnBody: string,
        sCity: string,
        sState: string,
        sCountry: string,
        sAddress: string,
        nContactNumber: number,
        sEmail: string
    ) {
        this.Firstname = sFirstname;
        this.Middlename = sMiddlename;
        this.Lastname = sLastname;
        this.Gender = sGender;
        this.Speciality = sSpeciality;
        this.Profession = sProfession;
        this.DateOfBirth = dDateOfBirth;
        this.RegnNumber = nRegnNumber;
        this.RegnBody = sRegnBody;
        this.City = sCity;
        this.State = sState;
        this.Country = sCountry;
        this.Address = sAddress;
        this.ContactNumber = nContactNumber;
        this.Email = sEmail;
    }
}
export class questionaire {
    public questionOption: string
    constructor(
        questionOption: string
    ) {
        this.questionOption = questionOption;
    }
}




export class LicenseModel {
    public LicenseCount: number;
    public FreeLicenseCount: number;
    public CustomerId:number;
    public Status:number;
    public TotalCost:number;
    public PaymentType:number;
    public UserId:number;    
    constructor(
        nLicenseCount: number,
        nFreeLicenseCount: number,        
        nCustomerId: number,
        nStatus: number,
        nTotalCost: number,
        nPaymentType: number,
        nUserId: number,
    ) {
        this.LicenseCount = nLicenseCount;
        this.FreeLicenseCount = nFreeLicenseCount;
        this.CustomerId = nCustomerId;
        this.Status = nStatus;
        this.TotalCost = nTotalCost;
        this.PaymentType = nPaymentType;
        this.UserId = nUserId;
    }
}
export class CustomerManagementElement {

    public Firstname: string;
    public Middlename: string;
    public Lastname: string;
    public Gender: string;
    public Speciality: string;
    public Profession: string;
    public DateOfBirth: any;
    public RegnNumber: number;
    public RegnBody: string;
    public City: string;
    public State: string;
    public Country: string;
    public Address: string;
    public ContactNumber: number;
    public Email: string;
    public CustomerId: number;
    public OrganizationName: string;
    public RoleId: number;
    public UserId: number;

    constructor(
        sFirstname: string,
        sMiddlename: string,
        sLastname: string,
        sGender: string,
        sSpeciality: string,
        sProfession: string,
        dDateOfBirth: any,
        nRegnNumber: number,
        sRegnBody: string,
        sCity: string,
        sState: string,
        sCountry: string,
        sAddress: string,
        nContactNumber: number,
        sEmail: string,
        nCustomerId: number,
        sOrganizationName: string,
        nRoleId: number,
        nUserId: number
    ) {
        this.Firstname = sFirstname;
        this.Middlename = sMiddlename;
        this.Lastname = sLastname;
        this.Gender = sGender;
        this.Speciality = sSpeciality;
        this.Profession = sProfession;
        this.DateOfBirth = dDateOfBirth;
        this.RegnNumber = nRegnNumber;
        this.RegnBody = sRegnBody;
        this.City = sCity;
        this.State = sState;
        this.Country = sCountry;
        this.Address = sAddress;
        this.ContactNumber = nContactNumber;
        this.Email = sEmail;
        this.CustomerId = nCustomerId;
        this.OrganizationName = sOrganizationName;
        this.RoleId = nRoleId;
        this.UserId = nUserId;
    }
}


export class PaymentReceived {
    public LicenseCount: number;
    public FreeLicenseCount: number;
    public CustomerId:number;
    public Status:number;
    public TotalCost:number;
    public PaymentType:number;
    public TransactionNumber:string;
    public UserId:number;   
    public PaymentDate:string; 
    constructor(
        nLicenseCount: number,
        nFreeLicenseCount: number,        
        nCustomerId: number,
        nStatus: number,
        nTotalCost: number,
        nPaymentType: number,
        sTransactionNumber:string,
        nUserId: number,
        nPaymentDate:string
    ) {
        this.LicenseCount = nLicenseCount;
        this.FreeLicenseCount = nFreeLicenseCount;
        this.CustomerId = nCustomerId;
        this.Status = nStatus;
        this.TotalCost = nTotalCost;
        this.PaymentType = nPaymentType;
        this.TransactionNumber = sTransactionNumber;
        this.UserId = nUserId;
        this.PaymentDate = nPaymentDate;
    }
}

export class CustomerProfile {
    public OrganizationName: string;
    public ContactPersonName: string;
    public EmailId: string;
    public MobileNo: number;
    public LandlineNo: number;
    public Country: string;
    public City: string;
    public State: string;
    public Address: string;
    public AdminEmailId: string;
    public GST: string;
    public UserId: number;
    public CustomerCode:string;
    public FileId:number;
    constructor(
        sOrganizationName: string,
        sContactPersonName: string,
        sEmailId: string,
        nMobileNo: number,
        nLandlineNo: number,
        sCountry: string,
        sCity: string,
        sState: string,
        sAddress: string,
        sAdminEmailId: string,
        sGST: string,
        nUserId: number,
        sCustomerCode:string,
        nFileId:number
    ) {
        this.OrganizationName = sOrganizationName;
        this.ContactPersonName = sContactPersonName;
        this.EmailId = sEmailId;
        this.MobileNo = nMobileNo;
        this.LandlineNo = nLandlineNo
        this.Country = sCountry;
        this.City = sCity;
        this.State = sState;
        this.Address = sAddress;
        this.AdminEmailId = sAdminEmailId;
        this.GST = sGST;
        this.UserId = nUserId;
        this.CustomerCode = sCustomerCode;
        this.FileId = nFileId;
    }
}