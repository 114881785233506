
import { Injectable } from '@angular/core';


import * as CryptoJS from 'crypto-js';

@Injectable()

//Encrypt Login

export class CryptoService {
  constructor() { }

  encryptStringCrypto(data, key): string {
    key = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
      {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();

    return encrypted;
  }

  decryptCryptoToString(data, key): string {
    key = CryptoJS.enc.Utf8.parse(key);
    let decrypted = CryptoJS.AES.decrypt(data, key,
      {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  decryptCryptoToJSON(data, key): JSON {
    key = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.AES.decrypt(data, key,
      {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }

}

